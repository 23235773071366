import { BaseRepository } from 'modules/core/services/base-repository';

export class SealingOrdersAwaitingAssignmentRepository extends BaseRepository {

    baseUrl = 'sealing-orders/awaiting-assignment';

    /**
     * Exports a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    export(criteria) {
        return this.httpClient.rawPost(`${this.baseUrl}/export`, criteria)
            .then(response => {
                if (response.status !== 200) {
                    throw { status: false, message: 'Error' };
                } else {
                    return response.blob();
                }
            });
    }

    /**
     * Assigns the given records
     *
     * @param id
     * @param data
     *
     * @returns {*}
     */
    assign(data) {
        return this.httpClient.patch(`${this.baseUrl}/assign`, data);
    }

    /**
     * Cancels a record
     *
     * @param id
     *
     * @returns {*}
     */
    cancel(id) {
        return this.httpClient.patch(`${this.baseUrl}/${id}/cancel`);
    }

}
