import { bindable, inject }                          from 'aurelia-framework';
import { AppContainer }                              from 'resources/services/app-container';
import { BaseFormViewModel }                         from 'base-form-view-model';
import { DialogController }                          from 'aurelia-dialog';
import { FormSchema }                                from 'modules/sealing-orders/awaiting-assignment/edit/form-schema';
import { SealingOrdersAwaitingAssignmentRepository } from 'modules/sealing-orders/awaiting-assignment/services/repository';

@inject(AppContainer, DialogController, FormSchema, SealingOrdersAwaitingAssignmentRepository)
export class AssignSealingOrder extends BaseFormViewModel {

    formId = 'sealing-orders-awaiting-assignment-edition-form';

    @bindable alert;
    @bindable settings = {
        title: 'form.title.assign-inspecting-entity',
        size:  'modal-md',
    };

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogController
     * @param formSchema
     * @param repository
     */
    constructor(appContainer, dialogController, formSchema, repository) {
        super(appContainer);

        this.dialogController = dialogController;
        this.formSchema       = formSchema;
        this.repository       = repository;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'sealing-orders.awaiting-assignment.manage',
            'sealing-orders.awaiting-assignment.edit',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params) {
        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.fetchData(params).then(() => this.schema = this.formSchema.schema(this));
    }

    /**
     * Submits view form
     */
    submit() {
        this.alert = null;

        return this.repository
            .assign(this.model)
            .then(response => this.afterSubmitCallback(response))
            .then(response => response.status)
            .catch(console.log.bind(console));
    }

    /**
     * Handles the submit form response
     *
     * @param response
     */
    afterSubmitCallback(response) {
        if (response.status === true) {
            this.appContainer.notifier.successNotice(response.message);

            this.initialModel.assign(this.model);
        } else {
            this.alert = this.alertMessage(response.status, response.message, response.errors);
        }

        return response;
    }

    /**
     * Fetches data from server
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    fetchData(params) {
        return new Promise((resolve, reject) => {
            this.initialModel.assign(params);
            this.model.assign(params);

            resolve(true);
            reject(new Error('Error'));
        });
    }

}
